import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getProfile(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/GetProfile", filter, authHeaders);
    return res.data;
  },
  async saveProfile(upload) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/Save", upload, authHeaders);
    return res.data;
  },
  async saveProfileImage(upload) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/SaveImage", upload, authHeaders);
    return res.data;
  },
  async getStates(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/GetStates", filter, authHeaders);
    return res.data;
  },
  async getCanadianProvinces(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Profile/GetCanadianProvinces",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getCountries(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/GetCountries", filter, authHeaders);
    return res.data;
  },
  async getRevenue(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/GetRevenue", filter, authHeaders);
    return res.data;
  },
  async getNumberEmployees(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Profile/GetNumberEmployees",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getIndustries(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/GetIndustries", filter, authHeaders);
    return res.data;
  },
  async getTradeShows(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/GetTradeShows", filter, authHeaders);
    return res.data;
  },
  async getClients(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/GetClients", filter, authHeaders);
    return res.data;
  },
  async getSites(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/GetSites", filter, authHeaders);
    return res.data;
  },
  async deleteTradeShow(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Profile/DeleteTradeShow",
      order,
      authHeaders
    );
    return res.data;
  },
  async deleteClient(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/DeleteClient", order, authHeaders);
    return res.data;
  },
  async saveTradeShow(upload) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/SaveTradeShow", upload, authHeaders);
    return res.data;
  },
  async saveClient(upload) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/Profile/SaveClient", upload, authHeaders);
    return res.data;
  },
  async saveTradeShowOrder(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Profile/SaveTradeShowOrder",
      order,
      authHeaders
    );
    return res.data;
  },
  async saveClientOrder(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Profile/SaveClientOrder",
      order,
      authHeaders
    );
    return res.data;
  },
};
